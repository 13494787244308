<template>
  <div class="mt-3 px-0">
    <v-card tile class="pb-3" flat>
      <v-card-title>
        Archive Project List
        <v-spacer></v-spacer>
        <v-text-field v-model.lazy="search" prepend-inner-icon="mdi-magnify" :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch" label="Search" single-line hide-details dense outlined class="mr-4 border-radius-6"
          :style="{ maxWidth: '300px' }" v-debounce="500"></v-text-field>
      </v-card-title>
      <v-data-table :loading="loading" :headers="headers" :items="projects" :search="search" :items-per-page="15"
        class="custom-data-table mx-4">
        <!-- project name with color -->
        <template v-slot:[`item.name`]="{ item }">
          <div>
            <Avatar :size="'30px'" :color="item.project_color_hex || 'blue lighten-3'"
              :intials="item.name ? item.name.charAt(0).toUpperCase() : 'P'" class="mr-2 my-2" />
            {{ item.name }}
          </div>
        </template>

        <!-- start date -->
        <template v-slot:[`item.start_date`]="{ item }">
          {{ convertDateFormat(item.start_date) }}
        </template>

        <!-- start date -->
        <template v-slot:[`item.project_status`]="{ item }">
          {{ item.projectstatus.name }}
        </template>

        <!-- Active -->
        <!-- <template v-slot:[`item.is_active`]="{ item }">
          <v-btn icon small @click="restoreProject(item.project_id)">

          </v-btn>
        </template> -->


        <!-- action links -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span @click="restoreProject(item.project_id)" class="cursor-pointer mr-3" v-bind="attrs" v-on="on">
                <v-icon style="font-size: 19px; bottom: 4px;"> mdi-restore </v-icon>
                <!-- <EyeIcon :size="20" /> -->
              </span>
            </template>
            <span>Restore</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span @click.stop="deleteItem(item.project_id)" class="cursor-pointer" v-bind="attrs" v-on="on">
                <TrashIcon :size="19" />
              </span>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-model="dialogDelete" max-width="450px">
        <v-card>
          <v-card-title class="headline">Alert</v-card-title>
          <v-card-title class="subtitle-1 mb-4">Are you sure you want to Delete this Project ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="red darken-1" text @click="deleteItemConfirm">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>


    <v-snackbar v-model="snackbar" :timeout="3000" color="green accent-4" align-baseline="center">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbarError" :timeout="3000" color="deep-orange accent-4" align-baseline="center">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>


    <!-- Dialog for confirmation of restore of the project -->
    <v-dialog v-model="restoreDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline"> Alert </v-card-title>
        <v-card-text class="subtitle-1">Are you sure you want to restore this Project?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeRestore">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="restoreItemConfirm">Restore</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations } from "vuex";
import debounce from "v-debounce";
import Avatar from "../common/basic/Avatar.vue";
import TrashIcon from "../../assets/icons/TrashIcon.vue";


export default {
  data: () => ({
    restoreProjectId: '',
    restoreDialog: false,
    deleteProjectDataId: '',

    enableEstimatedHours: false,
    e1: 1,
    search: "",
    searchInput: null,
    dialogDelete: false,
    form_title: "",
    message: "",
    errors: {},
    project_status: [],
    loading: false,
    snackbar: false,
    snackbarError: false,
    text: "hello",
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      {
        text: "Start Date",
        align: "start",
        value: "start_date",
      },
      {
        text: "Status",
        align: "start",
        value: "project_status",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    projects: [],
    projectAddStartDateFormat: moment(new Date()).format("DD-MM-YYYY"),

    date: new Date().toISOString().substr(0, 10),
    menu: false,
    timer: null,
    validation: {
      nameRules: [(v) => !!v || "Name is required"],
      estHourRules: [
        (v) => !!v || "Estimated hours for development is required",
        (v) => Number.isInteger(Number(v)) || "The value must be number",
      ],
      estHourQARules: [
        (v) => !!v || "Estimated hours for QA is required",
        (v) => Number.isInteger(Number(v)) || "The value must be number",
      ],
    },
    statusRule: [(v) => !!v || "Status is required"],

    friends: [],

  }),
  components: { Avatar, TrashIcon },
  mounted() {
    this.get_project_list();
    this.getProjectStatus();
    this.search = this.$store.state.search.search;
  },
  computed: {
    formattedDate() {
      if (this.item.start_date) {
        return moment(this.item.start_date).format("DD-MM-YYYY");
      }
      return "";
    },
  },
  watch: {
    search() {
      this.setSearch({
        search: this.search,
      });
    },
  },
  methods: {
    ...mapMutations("search", ["setSearch"]),

    restoreProject(projrctId) {
      this.restoreDialog = true;
      this.restoreProjectId = projrctId;
    },
    restoreItemConfirm() {
      this.loading = true;
      this.$axios
        .put("/restore_project/" + this.restoreProjectId)
        .then((res) => {
          let _self = this;
          let data = res.data;

          if (data.success == false) {
            _self.errors = {};
            _self.snackbarError = true;
            _self.message = data.message;
          } else {
            this.restoreDialog = false;
            _self.snackbar = true;
            _self.message = data.message;
            this.get_project_list();
          }
          _self.loading = false;
        });
    },
    closeRestore() {
      this.restoreDialog = false;
      this.restoreProjectId = '';
    },
    clearSearch() {
      this.search = '';
    },

    getProjectStatus() {
      this.$axios.get("project_statuses").then((res) => {
        // let _self = this;
        let response = res.data;

        if (response.success) {
          // project_status
          this.project_status = response.statuses;
        }
      });
    },

    convertDateFormat(date) {
      if (date) {
        return moment(date).format("Do MMMM YYYY");
      }
    },

    get_project_list() {
      this.loading = true;
      this.$axios.get("archive_project").then((res) => {
        this.projects = res.data.projects;
        this.loading = false;
      });
    },

    deleteItem(project_id) {
      // this.editedIndex = this.projects.indexOf(item);
      this.deleteProjectDataId = project_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let _self = this;
      this.$axios
        .delete("/delete_project_alldata/" + this.deleteProjectDataId)
        .then((res) => {
          if (!res.data.success) {
            _self.snackbarError = true;
            _self.message = res.data.message;
          } else {
            _self.snackbar = true;
            _self.message = res.data.message;
            this.get_project_list();
          }
        });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.deleteProjectDataId = '';
    },

    clearInput() {
      this.searchInput = null;
    },
  },
  directives: {
    debounce,
  },
};
</script>

<style>
.team-member-select {
  color: var(--primary-font-color) !important;
}

.team-member-select fieldset {
  border: 3px solid var(--border-color) !important;
  border-radius: 6px 6px 0px 0px !important;
}

.team-member-select .v-label {
  top: 16px !important;
}

.team-member-select .v-select__slot {
  padding: 6px 0px !important;
}

.selected-team-member-container {
  border-top: none;
  border: 2px solid var(--border-color) !important;
  background: #f0f4f8;
  border-radius: 0px 0px 6px 6px !important;
}
</style>