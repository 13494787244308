<template>
  <v-avatar
    :color="color"
    class="avatar"
    :size="size"
  >
    <img
      v-if="profile_url"
      :src="profile_url"
      class="profile-img"
    />
    <span v-else class="intials">{{ intials }}</span>
  </v-avatar>
</template>

<script>
const defaultValues = {
  color: "grey lighten-2",
  size: "40px",
  profile_url: null,
  intials: "U"
}

export default {
  props: {
    color: {
      type: String,
      default: defaultValues.color
    },
    size: {
      type: String,
      default: defaultValues.size
    },
    profile_url: {
      type: String,
      default: defaultValues.profile_url
    },
    intials: {
      type: String,
      default: defaultValues.intials
    }
  }
}
</script>

<style scoped>
.avatar {
  color: white;
  cursor: pointer;
  background: #e5e5e5;
}

.intials {
  font-size: 12px;
}

.profile-img {
  object-fit: cover;
}
</style>